import PropTypes from "prop-types";
import { m } from "framer-motion";
import { useRef, useState } from "react";
import Slider from "react-slick";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Card,
  Button,
  Grid,
  Container,
  Typography,
  Popover,
  useMediaQuery,
} from "@mui/material";

// _mock_
import { _carouselsMembers } from "../../_mock";
// components
import Image from "../../components/Image";
import Iconify from "../../components/Iconify";
import { CarouselArrows } from "../../components/carousel";
import SocialsButtonTeam from "../../components/SocialsButtonTeam";
import { MotionViewport, varFade } from "../../components/animate";

//
import { IconButtonAnimate } from "../../components/animate";

import styled from "styled-components";

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    width: 180px;
    position: absolute;
    border-radius: 16px;
    margin-top: 10px;
    text-align: center;
    box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
    :hover {
      opacity: 1 !important;
    }
  }
`;

const BUTTON_SIZE = 80;

const InfoButtonContainer1 = styled.div`
position:absolute;
left:25%;
bottom:25px;
@media (max-width: 768px) {
left:15%;
},
`;

const InfoButtonContainer2 = styled.div`
position:absolute;
left:45%;
bottom:0px;
@media (max-width: 768px) {
  left:40%
},
`;

const InfoButtonContainer3 = styled.div`
position:absolute;
right:25%;
bottom:25px;
@media (max-width: 768px) {
 right:10%;
},
`;
const InfoButton = styled(IconButtonAnimate, {
  shouldForwardProp: (prop) => prop !== "filled",
})(({ filled, theme }) => ({
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  cursor: "pointer",
  // borderRadius: '50%',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  // backgroundColor: "white",

  "&:hover": {
    color: theme.palette.text.primary,
  },
  ...(filled && {
    // opacity: 0.48,
    // borderRadius: Number(theme.shape.borderRadius) * 1.5,
    color: "white",
    // backgroundColor: "white",
    "&:hover": {
      opacity: 1,
      color: theme.palette.common.white,
      backgroundColor: 'rgba(99, 115, 129, 0.08)',
    },
  }),
}));

const DetailsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
`;
const TeamPictureContainer = styled.div`
  margin-top: 50px;
  justify-content: center;
  display: flex;
  margin-bottom: 50px;
  border-radius: 16px;
  flex-direction: row;
  position: relative;
  margin: auto;
`;

const InvestorsContainer = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 20px;
  }
  span {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    a {
      font-size: 23px;
      display: flex;
      flex-direction: row;
    }
  }
`;
// ----------------------------------------------------------------------

export default function AboutTeam() {
  const carouselRef = useRef(null);
  const theme = useTheme();
  const [isInfoOpen, setInfoOpen] = useState([0, 0, 0]);
  const [infoIndex, setInfoIndex] = useState(0);
  const [click, setClicked] = useState(null);

  const isRTL = theme.direction === "rtl";

  const settings = {
    arrows: true,
    slidesToShow: 3,
    autoPlay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "0px",
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  const onClick = (event, index) => {
    setClicked(event.currentTarget);
    const arr = [0, 0, 0];
    arr[index] = 1;
    setInfoOpen(arr);
    setInfoIndex(index);
  };

  const handleClose = (event) => {
    setInfoOpen([0, 0, 0]);
    setClicked(null);
    setInfoIndex(null);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container component={MotionViewport} sx={{ pb: 10, textAlign: "center", pt: 10 }}>
      {/* <m.div variants={varFade().inDown}>
        <Typography
          component="p"
          variant="overline"
          sx={{ mb: 2, color: "text.secondary" }}
        >
          who made it all possible
        </Typography>
      </m.div> */}

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Core team
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mx: "auto",
            maxWidth: 630,
            color: (theme) =>
              theme.palette.mode === "light"
                ? "text.secondary"
                : "common.white",
          }}
        >
          Meet the minds behind Flowpoint.
        </Typography>
      </m.div>

      <Box sx={{ position: "relative" }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ height: 1, mt: 3, mb: 3 }}
        >
          {/* <Grid item xs={12} md={12} style={{position:'relative'}}>
          <CarouselArrows filled onNext={handleNext} onPrevious={handlePrevious}>
            <Slider ref={carouselRef} {...settings}>
              {_carouselsMembers.map((member, index) => {

                return (
                  <Box key={member.id} component={m.div} variants={varFade().in} sx={{ px: 1.5, py: 10 }}>
                    <MemberCard member={member} />
                  </Box>
                )
              })}
            </Slider>
          </CarouselArrows>
          </Grid> */}
          <Grid item xs={12} md={12}>
            <TeamPictureContainer>
              <StyledPopover
                open={isInfoOpen[0] || isInfoOpen[1] || isInfoOpen[2]}
                anchorEl={click}
                onClose={(event) => {
                  if (handleClose) {
                    handleClose(event);
                  }
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Card key={_carouselsMembers[infoIndex]?.name} sx={{ p: 1 }}>
                  {/* <Image alt={_carouselsMembers[infoIndex]?.name} src={_carouselsMembers[infoIndex]?.avatar} ratio="1/1" objectFit="contain" sx={{ borderRadius: 1.5, height: 250 }} /> */}
                  <Typography variant="subtitle1" sx={{ mt: 3, mb: 0.5 }}>
                    {_carouselsMembers[infoIndex]?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mb: 1, color: "text.secondary" }}
                  >
                    {_carouselsMembers[infoIndex]?.role}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                {description}
              </Typography> */}
                  <Stack alignItems="center" sx={{ mt: 2, mb: 1 }}>
                    <SocialsButtonTeam
                      sx={{ color: "action.active" }}
                      links={_carouselsMembers[infoIndex]?.links}
                    />
                  </Stack>
                </Card>
                {/* <h3>{_carouselsMembers[infoIndex]?.name}</h3>
             <span>{_carouselsMembers[infoIndex]?.position}</span> */}
              </StyledPopover>
              <Image
                src={
                  isMobile ? "/images/team_mobile.webp" : "/images/team.webp"
                }
                sx={{ borderRadius: "16px", maxHeight: 500, minHeight: 300 }}
                objectPosition="bottom"
              />

              <InfoButtonContainer1>
                <InfoButton
                  filled={true}
                  onClick={(event) => onClick(event, 0)}
                  theme={theme}
                >
                  <Iconify
                    icon={"akar-icons:info"}
                    sx={{
                      width: 40,
                      height: 40,
                      color: "white",
                      ...(isRTL && { transform: " scaleX(1)" }),
                    }}
                  />
                </InfoButton>
              </InfoButtonContainer1>
              <InfoButtonContainer2>
                <InfoButton
                  filled={true}
                  onClick={(event) => onClick(event, 1)}
                  theme={theme}
                >
                  <Iconify
                    icon={"akar-icons:info"}
                    sx={{
                      width: 40,
                      height: 40,
                      color: "white",
                      ...(isRTL && { transform: " scaleX(1)" }),
                    }}
                  />
                </InfoButton>
              </InfoButtonContainer2>
              <InfoButtonContainer3>
                <InfoButton
                  filled={true}
                  onClick={(event) => onClick(event, 2)}
                  theme={theme}
                >
                  <Iconify
                    icon={"akar-icons:info"}
                    sx={{
                      width: 40,
                      height: 40,
                      color: "white",
                      ...(isRTL && { transform: " scaleX(1)" }),
                    }}
                  />
                </InfoButton>
              </InfoButtonContainer3>
              {/* <DetailsContainer>
          <span>Vlad Niculescu</span>
          <span>Stefania Duma</span>
          <span>Andrei Lupascu</span>
          </DetailsContainer> */}
            </TeamPictureContainer>
          </Grid>
        </Grid>
      </Box>
      {/* <Box>
        <InvestorsContainer>
          <h1>Backed by</h1>
          <span><a target="_blank" href="https://sfccapital.com/" rel="noreferrer"><img src={'/images/sfcLogo.png'}></img></a></span>
        </InvestorsContainer>
      </Box> */}
      {/* <Button
        variant="outlined"
        color="inherit"
        size="large"
        endIcon={<Iconify icon={'ic:round-arrow-right-alt'} width={24} height={24} />}
        sx={{ mx: 'auto' }}
      >
        View all team members
      </Button> */}
    </Container>
  );
}

// ----------------------------------------------------------------------

MemberCard.propTypes = {
  member: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
  }),
};

function MemberCard({ member }) {
  const { name, role, avatar, description } = member;

  return (
    <Card key={name} sx={{ p: 1 }}>
      <Image
        alt={name}
        src={avatar}
        ratio="1/1"
        objectFit="contain"
        sx={{ borderRadius: 1.5, height: 250 }}
      />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
        {role}
      </Typography>
      {/* <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
        {description}
      </Typography> */}
      <Stack alignItems="center" sx={{ mt: 2, mb: 1 }}>
        <SocialsButton sx={{ color: "action.active" }} links={member.links} />
      </Stack>
    </Card>
  );
}
