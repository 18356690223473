import PropTypes from "prop-types"
import { m } from "framer-motion"
// @mui
import { alpha, styled, useTheme } from "@mui/material/styles"
import { Box, Grid, Link, Paper, Rating, Container, Typography } from "@mui/material"
// hooks
import useResponsive from "../../hooks/useResponsive"
// utils
import cssStyles from "../../utils/cssStyles"
import Image from "next/image"
// components
import Iconify from "../../components/Iconify"
import { MotionViewport, varFade } from "../../components/animate"

// ----------------------------------------------------------------------

const TESTIMONIALS = [
    {
        name: "Nina",
        company: "Founder and CEO, Desyr",
        rating: 4,
        // dateCreate: "February 16, 2023",
        content: `It’s helped me to see where users are dropping out along the conversion funnel, so that I can make improvements to the website and increase our overall conversion rate.`,
    },
    {
        name: "Lucian N.",
        company: "Technology Strategist, Growth Linked LLC",
        rating: 5,
        // dateCreate: "February 17, 2023",
        content: `A Single Pane Of Glass for collecting, analyzing, and taking decisions for actions to be implemented in order to improve the conversions.Flexibility and responsiveness of the team to implement new features to help capture more insights about user behavior.`,
    },
    {
        name: "Nina",
        rating: 4,
        company: "Founder and CEO, Desyr",
        // dateCreate: "February 16, 2023",
        content: `Great user behaviour analytics tool.`,
    },
    {
        name: "Tori P.",
        rating: 5,
        company: "Co-founder, Qube App",
        // dateCreate: "February 20, 2023",
        content: `Easy of use. Colors. Insights. The fact that it can show me the bottlenecks in the path to conversion easily. The level of personalization.`,
    },
    {
        name: "Dhiraj N.",
        company: "CEO, Opsly Cloud",
        rating: 4,
        // dateCreate: "February 22, 2023",
        content: `The data generated is very useful to see the website visitor journey and where we can optimise our content and design to improve the score.`,
    },
]

const RootStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    padding: theme.spacing(10, 0),
    backgroundSize: "cover",

    // backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.grey[900], 0.8)} , ${alpha(
    //   theme.palette.grey[900],
    //   0.8
    // )}), url(https://minimal-assets-api.vercel.app/assets/images/about/testimonials.jpg)`,

    background: "#00140C",
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        padding: 0,
        height: 840,
        overflow: "hidden",
    },
    "& img": {
        // maxHeight: "60px",
        // [theme.breakpoints.down("md")]: {
        //   borderRadius: 8,
        //   height: 'auto'
        // },
    },
}))

// ----------------------------------------------------------------------

export default function AboutTestimonials() {
    const isDesktop = useResponsive("up", "md")

    return (
        <RootStyle>
            <Container component={MotionViewport} sx={{ position: "relative", height: 1 }}>
                <Grid container spacing={3} alignItems='center' justifyContent={{ xs: "center", md: "space-between" }} sx={{ height: 1 }}>
                    <Grid item xs={10} md={6}>
                        <Box sx={{ maxWidth: { md: 460 } }}>
                            <m.div variants={varFade().inUp}>
                                <Typography component='p' variant='overline' sx={{ mb: 2, color: "#f4f4f4" }}>
                                    Testimonials
                                </Typography>
                            </m.div>

                            <m.div variants={varFade().inUp}>
                                <Typography
                                    variant='h2'
                                    sx={{
                                        mb: 3,
                                        color: "white",
                                    }}
                                >
                                    Who loves <br />
                                    Flowpoint?
                                </Typography>
                            </m.div>

                            <m.div variants={varFade().inUp}>
                                <Typography
                                    sx={{
                                        color: "common.white",
                                        fontWeight: "500",
                                        lineHeight: 1.5,
                                        fontSize: "1rem",
                                        fontFamily: "Inter",
                                    }}
                                    variant='body1'
                                >
                                    Business owners and marketing professionals are taking their online pressence to the next level.
                                </Typography>
                            </m.div>
                            <Grid
                                container
                                spacing={2}
                                alignItems={isDesktop ? "flex-start" : "center"}
                                justifyContent={isDesktop ? "flex-start" : "center"}
                                sx={{
                                    height: 1,
                                    mt: 5,
                                    //    background: "rgba(255, 255, 255, 0.09)",
                                    borderRadius: "8px",
                                    display: "flex",
                                }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    md={4}
                                    sx={{
                                        justifyContent: "center",
                                        padding: "10px!important",
                                        display: "flex",
                                    }}
                                >
                                    <a
                                        href='https://www.producthunt.com/posts/flowpoint?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flowpoint'
                                        target='_blank'
                                        aria-label='Read more reviews about Flowpoint.'
                                        rel='noreferrer'
                                        style={{ display: "flex" }}
                                    >
                                        {" "}
                                        <Image
                                            alt='Flowpoint - Boost&#0032;your&#0032;website&#0032;conversion&#0032;rates&#0032;with&#0032;AI | Product Hunt'
                                            border='0'
                                            height={154}
                                            width={550}
                                            src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400018&theme=light'
                                        />{" "}
                                    </a>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    md={4}
                                    sx={{ justifyContent: { xs: "center", md: "center" }, padding: "10px!important", display: "flex" }}
                                >
                                    <a
                                        href='https://theresanaiforthat.com/ai/flowpoint/?ref=featured&v=36463'
                                        target='_blank'
                                        aria-label='Read more reviews about Flowpoint.'
                                        rel='noreferrer'
                                        style={{ display: "flex", backgroundColor: "white", borderRadius: 2 }}
                                    >
                                        <Image
                                            alt='theresanaiforthat'
                                            border='0'
                                            height={35}
                                            width={134}
                                            sx={{ height: "35px", width: "133px", backgroundColor: "#E6E5E5" }}
                                            src='https://media.theresanaiforthat.com/featured4.png'
                                        />{" "}
                                    </a>
                                </Grid>
                            </Grid>

                            {/* {!isDesktop && (
                                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                    <m.div variants={varFade().inUp}>
                                        <TestimonialLink />
                                    </m.div>
                                </Box>
                            )} */}
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                            right: { md: 24 },
                            position: { md: "absolute" },
                        }}
                    >
                        <Grid container spacing={isDesktop ? 3 : 0} alignItems='center'>
                            <Grid item xs={12} md={6}>
                                {TESTIMONIALS.slice(0, 3).map((testimonial) => (
                                    <m.div key={testimonial.content} variants={varFade().inUp}>
                                        <TestimonialCard testimonial={testimonial} />
                                    </m.div>
                                ))}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                {TESTIMONIALS.slice(3, 6).map((testimonial) => (
                                    <m.div key={testimonial.content} variants={varFade().inUp}>
                                        <TestimonialCard testimonial={testimonial} />
                                    </m.div>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* {isDesktop && (
                    <Box sx={{ bottom: 60, position: "absolute" }}>
                        <m.div variants={varFade().inLeft}>
                            <TestimonialLink />
                        </m.div>
                    </Box>
                )} */}
            </Container>
        </RootStyle>
    )
}

// ----------------------------------------------------------------------

function TestimonialLink() {
    return (
        <Link
            href='https://www.capterra.com/reviews/282505/Flowpoint?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'
            target='_blank'
            variant='subtitle2'
            sx={{ display: "flex", alignItems: "center", color: "white" }}
        >
            Read more testimonials
            <Iconify icon={"ic:round-arrow-right-alt"} sx={{ ml: 1, width: 20, height: 20 }} />
        </Link>
    )
}

TestimonialCard.propTypes = {
    testimonial: PropTypes.shape({
        content: PropTypes.string,
        dateCreate: PropTypes.string,
        name: PropTypes.string,
        company: PropTypes.string,
        rating: PropTypes.number,
    }),
}

function TestimonialCard({ testimonial }) {
    const theme = useTheme()
    const { name, rating, dateCreate, content, company } = testimonial

    return (
        <Paper
            sx={{
                mt: 3,
                p: 3,
                color: "common.white",
                boxShadow: "#1a05052b -40px 40px 80px",
                ...cssStyles().bgBlur({
                    color: theme.palette.common.white,
                    opacity: 0.09,
                }),
            }}
        >
            <Typography variant='subtitle1' gutterBottom>
                {name}
            </Typography>
            <Typography variant='body2' gutterBottom>
                {company}
            </Typography>
            <Typography gutterBottom component='p' variant='caption' sx={{ color: "grey.500" }}>
                {dateCreate}
            </Typography>
            <Rating value={rating} readOnly size='small' />
            <Typography variant='body2' sx={{ mt: 1.5 }}>
                {content}
            </Typography>
        </Paper>
    )
}
