import { m } from "framer-motion"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Container, Typography } from "@mui/material"
// components
import { MotionContainer, TextAnimate, varFade } from "../../components/animate"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: "linear-gradient(to right bottom, #00150D, #091E16, #1E372B)",
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up("md")]: {
        height: 560,
        padding: 0,
    },
}))

const ContentStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        position: "absolute",
        bottom: theme.spacing(10),
    },
}))

// ----------------------------------------------------------------------

export default function AboutHero() {
    return (
        <RootStyle>
            <Container component={MotionContainer} sx={{ position: "relative", height: "100%" }}>
                <ContentStyle>
                    <TextAnimate text='Who' sx={{ color: "primary.main" }} variants={varFade().inRight} />
                    <br />
                    <Box sx={{ display: "inline-flex", color: "common.white" }}>
                        <TextAnimate text='we' sx={{ mr: 2 }} />
                        <TextAnimate text='are?' />
                    </Box>

                    <m.div variants={varFade().inRight}>
                        <Typography
                            variant='h4'
                            sx={{
                                mt: 5,
                                color: "common.white",
                                fontWeight: "fontWeightMedium",
                            }}
                        >
                            Let's work together and
                            <br /> make awesome site easily
                        </Typography>
                    </m.div>
                </ContentStyle>
            </Container>
        </RootStyle>
    )
}
