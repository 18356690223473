import { m } from "framer-motion";
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, Grid, Button, Container, Typography } from "@mui/material";
// components
import Image from "../../components/Image";
import { MotionViewport, varFade } from "../../components/animate";
import styledComponent from "styled-components";
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------
const InvestorsContainer = styledComponent.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 20px;
  }
  span {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    a {
      font-size: 23px;
      display: flex;
      flex-direction: row;
    }
  }
`;

const ImpactContainer = styledComponent.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
`;
const TypoContainer = styledComponent.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 20px;
`;
const IconWrapper = styledComponent.div`
    display: flex;
    border-radius: 50%;
    background-color: #FFC400;
    width: 24px;
    height: 24px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;
const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(5, 0),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(15),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    marginBottom: 0,
  },
}));

const ScreenStyle = styled(m.div)(({ theme }) => ({
  paddingRight: 2,
  paddingBottom: 1,
  maxWidth: 160,
  borderRadius: 8,
  backgroundColor:
    theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  [theme.breakpoints.up("sm")]: {
    maxWidth: 320,
    paddingRight: 4,
    borderRadius: 12,
  },
  "& img": {
    borderRadius: 8,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 12,
    },
  },
}));

const COMMON = {
  scaleX: 0.86,
  skewY: 8,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  opacity: 0,
};

const variantScreenLeft = {
  initial: COMMON,
  animate: { ...COMMON, translateX: "-50%", translateY: 40, opacity: 1 },
};

const variantScreenCenter = {
  initial: COMMON,
  animate: { ...COMMON, opacity: 1 },
};

const variantScreenRight = {
  initial: COMMON,
  animate: { ...COMMON, translateX: "50%", translateY: -40, opacity: 1 },
};

// ----------------------------------------------------------------------

export default function HomeHugePackElements() {
  const theme = useTheme();

  const isLight = theme.palette.mode === "light";

  const isRTL = theme.direction === "rtl";

  const screenLeftAnimate = variantScreenLeft;

  const screenCenterAnimate = variantScreenCenter;

  const screenRightAnimate = variantScreenRight;

  return (
    <MotionViewport disableAnimatedMobile={false}>
      <RootStyle>
        <Container>
          <Grid container  justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ContentStyle>
                {/* <m.div variants={varFade().inUp}>
                  <Typography
                    component="div"
                    variant="overline"
                    sx={{ mb: 2, color: "text.disabled" }}
                  >
                    PRE-SEED
                  </Typography>
                </m.div> */}

                <m.div variants={varFade().inUp}>
                  <Typography variant="h2" sx={{ mb: 5 }}>
                    Backed by SFC Capital
                    {/* <a
                      target="_blank"
                      href="https://sfccapital.com/"
                      rel="noreferrer"
                    >
                      <img src={"/images/sfcLogo.png"}></img>
                    </a> */}
                  </Typography>
                  {/* <InvestorsContainer>
                    <span>
                      <a
                        target="_blank"
                        href="https://sfccapital.com/"
                        rel="noreferrer"
                      >
                        <img src={"/images/sfcLogo.png"}></img>
                      </a>
                    </span>
                  </InvestorsContainer> */}
                </m.div>
                <m.div variants={varFade().inUp}>
                  <Typography
                    sx={{
                      mb: 5,
                      color: "text.secondary",
                    }}
                  >
                    In 2022, Flowpoint partnered with SFC Capital to supercharge product development and operations.
                  </Typography>
                </m.div>
                <m.div variants={varFade().inUp}>
                <ImpactContainer>
                    <div style={{ width: "25px" }}>
                      <IconWrapper>
                        <Iconify
                          icon={"bi:check-lg"}
                          color="#000"
                          width={14}
                          height={14}
                        />
                      </IconWrapper>{" "}
                    </div>
                    <TypoContainer>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                       Together we have a greater mission
                      </Typography>
                      <Typography
                        sx={{
                          mb: 5,
                          color: "text.secondary",
                        }}
                      >
                      Our mission is to become the leader in the AI powered behaviour analytics space.
                      </Typography>
                    </TypoContainer>
                  </ImpactContainer>
                  <ImpactContainer>
                    <div style={{ width: "25px" }}>
                      <IconWrapper>
                        <Iconify
                          icon={"bi:check-lg"}
                          color="#000"
                          width={14}
                          height={14}
                        />
                      </IconWrapper>{" "}
                    </div>
                    <TypoContainer>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Who is SFC?
                      </Typography>
                      <Typography
                        sx={{
                          mb: 5,
                          color: "text.secondary",
                        }}
                      >
                      SFC Capital (formerly known as Startup Funding Club) is an investment fund manager and network of business angels founded in 2012 by Stephen Page, the CEO of the company. It manages the SFC Angel Fund which invests in small companies qualifying for the SEIS and EIS investment schemes in the UK.
                      </Typography>
                    </TypoContainer>
                  </ImpactContainer>

                 
                </m.div>
              </ContentStyle>
            </Grid>

            <Grid item xs={12} md={6} sx={{paddinfLeft: '0px!important', paddingTop: '0px!important'}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                  border: "1px solid #dfdfdfb5",
                  borderRadius: "12px",
                  padding: "10px",
                  marginLeft: {md: "20px"},

                }}
              >
                <img
                  // disabledEffect
                  alt={"sfc"}
                  src="/images/tweet.webp"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </MotionViewport>
  );
}
