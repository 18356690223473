import { m } from "framer-motion"
// @mui
import { Box, Container, Typography, Grid } from "@mui/material"
// components
import Image from "next/image"
import { MotionViewport, varFade } from "../../components/animate"
import styled from "styled-components"

// ----------------------------------------------------------------------
const InvestorsContainer = styled.div`
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    h1 {
        margin-bottom: 20px;
    }
    span {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        a {
            font-size: 23px;
            display: flex;
            flex-direction: row;
        }
    }
`

export default function AboutVision() {
    return (
        <Container component={MotionViewport} sx={{ mt: 10 }}>
            <m.div variants={varFade().inRight}>
                <Typography variant='h2' sx={{ mb: 3 }}>
                    What is minimals
                </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
                <Typography
                    sx={{
                        color: "text.secondary",
                    }}
                >
                    Our theme is the most advanced and user-friendly theme you will find on the market, we have documentation and video to
                    help set your site really easily, pre-installed demos you can import in one click and everything from the theme options
                    to page content can be edited from the front-end. This is the theme you are looking for.
                </Typography>
            </m.div>
            <Box>
                <InvestorsContainer>
                    <h1>Backed by</h1>
                    <span>
                        <a target='_blank' href='https://sfccapital.com/' rel='noreferrer'>
                            <img src={"/images/sfcLogo.png"}></img>
                        </a>
                    </span>
                </InvestorsContainer>
            </Box>
            <Box
                sx={{
                    mb: 10,
                    position: "relative",
                    borderRadius: 2,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Image src='/images/tweet.png' alt='about-vision' height={600} width={520} style={{ border: "1px solid grey" }} />
                {/* 
        <Box
          sx={{
            bottom: { xs: 24, md: 80 },
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center',
          }}
        >
          {['logo_amazon', 'logo_hbo', 'logo_ibm', 'logo_lya', 'logo_spotify', 'logo_netflix'].map(
            (logo) => (
              <m.div key={logo} variants={varFade().in}>
                <Image
                  alt={logo}
                  src={`https://minimal-assets-api.vercel.app/assets/images/logos/${logo}.svg`}
                  sx={{
                    m: { xs: 1.5, md: 3 },
                    height: { xs: 24, md: 32 },
                  }}
                />
              </m.div>
            )
          )}
        </Box> */}
            </Box>

            {/* <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <m.div variants={varFade().inUp}>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
              Our vision offering the best product nulla vehicula tortor scelerisque ultrices
              malesuada.
            </Typography>
          </m.div>
        </Grid>
      </Grid> */}
        </Container>
    )
}
